@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;600;700&display=swap');

.App {
  box-sizing: border-box;
  text-align: center;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  line-height: 19px;
  min-height: 100vh;
  width: 100%;
}

button {
  color: black !important;
}

.header-image {
  position: relative;
  height: 20rem;
  background-image: url('./assets/blue-header-bg.png');
  background-size: contain;
  /* background-repeat: round; */
  z-index: 1000;
  border-radius: 20px;
  margin-top: -29px;
  box-shadow: 0px 4px 4px 0px #00000040;
}
  
.cam-up-logo {
  background-image: url('./assets/CamUp-white.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 406px;
}

.header-logo img {
  height: 100px;
  position: relative;
  right: 0;
  left: 0;
  top: 42px;
}
/* 
.main {
  position: relative;
  padding: 40px 0px;
  margin-top: -20px;
  padding-bottom: 100px;
  background: #121212;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
} */

.main {
  position: relative;
  background: #121212;
  min-height: 100vh;
}

@media only screen and (max-width: 390px) {
  .main {
    padding: 24px 0;
  }

  .vote-stats-container {
    justify-content: space-between;
  }

  .upvote-description {
    padding-left: 12px;
  }
}

@media only screen and (min-width: 1750px) {
  .main {
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.camup-upload-form {

}

h1 {
  color: white;
}

.App-link {
  color: #61dafb;
}

.play-button {
  border-radius: 50%;
  width: 58px;
  height: 58px;
  background: #00C5FF;
  cursor: pointer;
  border: none;
}

/* .song-card.upvoted {
  border-color: #00CE62;
} */

.song-card.upvoted .vote-count-value {
  color: #00CE62;
}

.song-card.upvoted button {
  background-color: #00CE62;
}

.song-name.playing, .folder-name.playing {
  color: #0C8E4A;
}

.song-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 8px;
  padding: 18px 16px;
  box-sizing: border-box;
  height: 94px;
  text-align: left;
  border-radius: 5px;
  border: 1px solid #D3D3D3;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.song-name {
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
}

.song-name.playing {
  text-overflow: unset;
  white-space: nowrap;
  overflow: unset;
}

.song-name-scrolling {
  animation: scroll-left 10s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media only screen and (max-width: 501px) {
  .song-name {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .song-info-section {
    width: 126px !important;
  }
}

.leaderboard .rank {
  /* background: #79EBAF;
  border-width: 0px 1px;
  border-style: solid;
  border-color: #000000;
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 91px; */
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.leaderboard .rank.one {
  background: linear-gradient(161.16deg, #FFC000 0%, #FF5E99 52.82%, #FF5E99 90.55%);
  height: 94px;
  border-top: 1px solid black;
  margin-top: -6px;
}

.leaderboard .vote-section {
  width: 71px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 90px;
}

.leaderboard .user-info {
  display: flex;
}

.leaderboard .vote-count-value {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #000000;
  margin: 6px 0;
}

.rank p:first-of-type,
.vote-section p:first-of-type {
  margin: 0;
  margin-top: 8px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

.folder-name {
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.song-info-section {
  display: flex;
  width: 262px;
  margin: 0 16px;
  position: relative;
  overflow: hidden;
}

.leaderboard .song-info-section {
  margin: 0;
}

.play-button-section,
.song-info-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.play-button {
  display: flex;
  justify-content: center;
  align-items: center;
}


.banner-container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
}

.music-player {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 6rem;
  right: 0;
  background: rgb(255, 252, 247);

}

.vote-stats-container {
  display: flex;
  justify-content: space-around;
}

.leaderboard-card {
  position: relative;
  border-radius: 10px;
  border: 1px solid var(--stroke-gray, #D3D3D3);
  background: linear-gradient(135deg, #FF005D 0%, #FF468A 6.53%, #FF005D 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 246px;
  height: 228px;
  margin-top: -153px;
  z-index: 1000;
  color: #FFF;
  font-family: Nunito;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  padding: 16px;
}

.leaderboard-card div {
  display: flex;
  justify-content: end;
}

.player-card {
  position: relative;
  border-radius: 10px;
  border: 1px solid var(--stroke-gray, #D3D3D3);
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 246px;
  height: 228px;
  margin-top: -153px;
  margin-bottom: 32px;
  z-index: 1000;
}

.player-card .level {
  color: #404040;
  font-size: 14px;
  font-weight: 400;
}

.vote-stats-card, .ear-status-rank {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  padding: 10px;
  width: 159px;
  height: 91px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 2px solid #000000;
  box-shadow: 3px 4px 1px #000000;
}

.voter-profile-card {
  border-radius: 5px;
  justify-content: center;
  margin-bottom: 10px;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid var(--stroke-gray, #D3D3D3);
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  min-width: 230px;
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  color: #4A4A4A;
}

.voter-profile-card p {
  display: flex;
  justify-content: space-between;
}

.liked-music-card {
  border-radius: 5px;
  justify-content: center;
  margin-bottom: 10px;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid var(--stroke-gray, #D3D3D3);
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  min-width: 230px;
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  color: #4A4A4A;
}

.liked-music-card h2,
.voter-profile-card h2 {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.credits {
  font-weight: bold;
  font-size: 12px;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 52px;
  right: 24px;
  border-radius: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 6px;
  background: #FFFFFF;
  border: 1px solid #D3D3D3;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.music-profile-card {
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid var(--stroke-gray, #D3D3D3);
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  min-width: 230px;
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  color: #4A4A4A;
}

.music-profile-segment-content-songs {
  padding: 10px 0;
  box-sizing: border-box;
  min-width: 230px;
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  color: #4A4A4A;
  border-bottom: 1px solid var(--stroke-gray, #D3D3D3);
  background: #FFF;
}

.music-profile-segment {
  margin-bottom: 3px;
}

.music-profile-segment-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.music-profile-segment-header {
  display: flex;
  justify-content: space-between;
  width: 75%;
  padding: 5px 19px;
  border-radius: 40px;
  border: 1px solid var(--stroke-gray, #D3D3D3);
  background: #FFF;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
}

.music-profile-segment-header h3,
.music-profile-segment-header p {
  margin: 0px;
}

.music-profile-segment-content {
  display: block;
  margin-top: 2px;
  margin-left: 18px;
}

.music-profile-segment-button {
  background-color: #00CE62;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 29px;
  border: none;
  color: white !important;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.hashtag-tag {
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  padding: 4px 8px 4px 8px;
  border-radius: 50px;
  font-size: 8px;
  box-shadow: 0px 1px 1px 0px #00000040;
  text-align: right;
  color: #6d6d6d
}

.disliked { 
  background: linear-gradient(0deg, #d5b0b0, #d5b0b0), linear-gradient(0deg, #d5b0b0, #d5b0b0);
  color: white;
}

.liked { 
  background: linear-gradient(0deg, #4caf50, #4caf50), linear-gradient(0deg, #EAEAEA, #EAEAEA);
  color: white;
}

.pending {
  background: linear-gradient(0deg, #D3D3D3, #D3D3D3), linear-gradient(0deg, #EAEAEA, #EAEAEA);
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.custom-file-upload {
  padding: 20px;
  cursor: pointer;
  background: #1b1b1b;
  border: 2px solid #3e8cac;
  border-radius: 5px;
  text-align: center;
  color: white;
  margin-bottom: 10px;
  width: 300px;
}

.no-music-uploaded {
  margin-top: 64px;
}

.custom-file-upload {
  padding: 16px;
  cursor: pointer;
  background: #1b1b1b;
  border: 1px solid #3e8cac;
  border-radius: 4px;
  margin: 6px 0;
  color: white;
  height: 84px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  align-items: center;
}

.custom-upload-small-text {
  font-size: 14px;
  color: #b9b9b9;
}

.color-white {
  color: white;
}

.custom-file-upload.button {
  background: #FFFFFF;
  border: 1px solid #D3D3D3;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.custom-file-upload-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.upload-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #121212;
  min-height: 100vh;
  color: white;
  position: relative;
  padding-bottom: 80px; /* Extra padding to accommodate fixed buttons */
}

.logo {
  width: 112px;
}

.upload-page h1 {
  margin-bottom: 20px;
}

.custom-file-upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.custom-file-upload.button {
  background: #00CE62;
  border: none;
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.uploaded-files, .existing-songs {
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
}

.uploaded-files h2, .existing-songs h2 {
  margin-bottom: 16px;
  text-align: left;
  color: white;
  font-size: 18px;
}

.upload-limit-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 300px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}



.file-item .file-info {
  text-align: left;
  width: 70%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.file-info {
  text-align: left;
  width: 70%; /* Adjust as needed */
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden; /* Ensures content does not overflow */
  text-overflow: ellipsis; /* Adds ellipsis when the content overflows */
  white-space: nowrap; /* Prevents text from wrapping to the next line */
}

.file-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #2b2b2b;
  border-radius: 16px;
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
  overflow: hidden;
  border: 1px solid #6d6d6d;
  min-height: 48px;
}

.file-item .file-status {
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: end;
}

.file-item.success::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;
  background-color: #00CE62;
}

.file-item.error::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;
  background-color: #FF5E5E;
}

.file-item .progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #DEDEDE;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}

.file-item .progress {
  height: 100%;
  background: #3e8cac !important; /* Blue color for the progress */
  transition: width 0.2s ease-in-out; /* Smooth transition for the width */
}

.file-item.success .progress {
  background: #00CE62; /* Green on success */
}

.file-item.error .progress {
  background: #FF5E5E; /* Red on error */
}

@keyframes spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-icon svg {
  animation: spinner-rotate 1s linear infinite; /* Adjust the duration as needed */
}


@keyframes progress-animation {
  0% { width: 0; }
  100% { width: 100%; }
}

.remove-button {
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 1.2rem;
}
.done-button {
  position: fixed;
  bottom: 68px;
  left: 50%;
  transform: translateX(-50%);
  background: #25B1FF;
  color: white !important;
  padding: 10px 40px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  z-index: 10;
  width: 280px;
}

.terms {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8rem;
  color: #888888;
  z-index: 10; /* Ensure it stays on top of other elements */
}



.matched-upvote {
  background-image: url('assets/matchedUpvote.png');
}

.matched-downvote {
  background-image: url('assets/mismatchedupvotes.png');
}

.matched-upvote, .matched-downvote {
  display: block;
  width: 48px;
  height: 48px;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
}

.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
	-webkit-clip-path: inset(50%) !important;
		clip-path: inset(50%) !important;  /* 2 */
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;            /* 3 */
}

@media only screen and (min-width: 390px) {
  .voter-profile-card {
    min-width: 342px;
  }
}

.ear-status-logo {
  position: relative;
  width: 88px;
  height: 90px;
  stroke-width: 6px;
  stroke: var(--inactive-button, #D9D9D9);
  background-color: #D9D9D9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ear-status-logo::after {
  background-image: url('./assets/ear-status--question.png');
  content: '';
  width: 13px;
  height: 19px;
  background-repeat: no-repeat;
  position: absolute;
  background-size: contain
}

.tab-nav {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
}

.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #121212;
  color: white !important;
  border: none;
  min-height: 48px;
  flex-grow: 1;
  font-weight: bold;
  font-size: 16px;
  max-width: 188px;
  border-bottom: 1px solid #6d6d6d;
}

.tab-button.active {
  border-color: #25B1FF;
  color: #25B1FF !important;
  border-bottom: 3px solid;
}

.tab-content {
  width: 100%;
  display: flex;
  justify-content: center;
}


@keyframes progress-animation {
  0% {
    width: 0;
    box-shadow: 0 0 5px rgba(0, 206, 98, 0.5);
  }
  50% {
    width: 50%;
    box-shadow: 0 0 10px rgba(0, 206, 98, 0.7);
  }
  100% {
    width: 100%;
    box-shadow: 0 0 15px rgba(0, 206, 98, 1);
  }
}

.progress-bar {
  background: #DEDEDE;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
}

.progress {
  width: 0;
  height: 100%;
  border-radius: 2px;
  box-shadow: 0 0 5px rgba(0, 206, 98, 0.5);
  animation: progress-animation 2s ease-in-out forwards;
}

.progress-wrapper {
  height: 8px;
  width: 100%;
  border-radius: 10px;
  background: #f1f1f1;
  overflow: hidden;
  position: relative;
  margin-top: 5px;
  transition: transform 0.2s ease-in-out;
}

.progress-wrapper:hover .progress {
  transform: scale(1.02);
  box-shadow: 0 0 20px rgba(0, 206, 98, 0.7);
}


.vote-stats-card {
  flex-direction: column;
  align-items: center;
}

.vote-stat-progress-value {
  position: absolute;
  z-index: 1;
  font-size: 12px;
}

.vote-stat-progress {
  /* width: 55px !important;
  height: 55px !important;
  display: flex;
  justify-content: center;
  align-items: center; */
}

.vote-stat-progress div {
  width: 55px !important;
  height: 55px !important;
}


.vote-stat-progress svg {
  width: 115px !important;
  height: 111px !important;
}
.ear-status {
  display: flex;
  justify-content: center;
  margin-top: -34px;
}

.ear-status-icon {
  position: absolute;
  left: 66px;
  top: -44px;
  z-index: 3000;
}

.ear-status-rank {
  font-weight: bold;
  font-size: 10px;
  height: 48px;
  width: 131px;
  left: 96px;
  top: 0px;
  border-radius: 50px;   
  margin-bottom: 25px;
  border: 1px solid #000000;
  align-items: center;
  justify-content: flex-start;
}

.user-triggers {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  width: 70px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.vote-button {
  height: 39px;
  width: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 3px;
  background-color: #D9D9D9;
  border: none;
  cursor: pointer;
  transition: background-color 0.5s;
}

.vote-button:hover {
  background-color: #d1d1d1;
}

.vote-count-value {
  text-align: center;
  margin-right: 3px;
  font-size: 36px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
}

.vote-count {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
}

.vote-count p {
  margin: 0;
}

.vote-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-refresh {
  color: white !important;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 209px;
  height: 55px;
  border: none;
  flex-shrink: 0;
  border-radius: 50px;
  background: #25B1FF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.button-refresh svg {
  fill: white;
}


.button-refresh svg path,
 .sign-in-button {
  color: black
}

.fullscreen-menu {
  position: fixed;
  top: 0;
  left: -300px;
  bottom: 0;
  display: block;
  width: 100%;
  max-width: 300px;
  height: 100%;
  transition: transform 300ms;
  background-color: #FFFFFF;
  z-index: 3000;
  color: #000000;
}

.fullscreen-menu.open {
  transform: translateX(300px);
  padding: 62px 23px;
}

@media only screen and (max-width: 530px) {
  .fullscreen-menu.open {
    max-width: 59%;
  }
}


.fullscreen-menu h1 {
  margin-bottom: 20px;
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.fullscreen-menu p {
  margin-bottom: 20px;  /* adjust as needed */
  text-align: left;
  max-width: 80%;  /* adjust as needed */
}

.fullscreen-menu button {
}

.menu-button {
  width: 48px;
  height: 48px;
  border: 1px;
  position: absolute;
  top: 52px;
  left: 24px;
  border-radius: 50%;
  border: 1px solid #D3D3D3;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button {
  position: absolute;
  top: 10px;  /* adjust as needed */
  right: 10px;  /* adjust as needed */
  font-size: 2rem;
  background: none;
  border: none;
  color: black;
}

.sign-up-input input, 
.sign-in-input input {
  width: 223px;
  height: 36px;
  background: #EDEEF0;
  border: 1px solid #000000;
  padding-left: 10px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #4A4A4A;
  margin-bottom: 10px;
}

.sign-up-name {
  display: flex;
  margin-bottom: 10px;
}

.sign-up-name .firstname {
  margin-right: 9px;
  width: 126px;
  background: #FFFFFF;
}

.sign-up-name .lastname {
  width: 127px;
}

.sign-up-name input {
  height: 36px;
  background: #EDEEF0;
  border: 1px solid #000000;
  padding-left: 10px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #4A4A4A;
}

.sign-up-button, .login-button {
  background: #FFC000;
  border: 2px solid #000000;
  width: 237px;
  height: 44px; 
  box-shadow: 3px 4px 1px #000000;        
  border-radius: 5px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: black;
}

.sign-out-button,
.nav-leaderboard,
.nav-vote  {
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  border: none;
  margin: 0 !important;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 237px;
}

.sign-in-button {
  border: 2px solid #000000;
  box-shadow: 3px 4px 1px #000000;
  border-radius: 5px;
}

.sign-up-input input {
  width: 95%;
}

.sign-up-input {
  /* padding: 10px; */
}

.login-button {
  background-color: #FFFFFF;
}

.sign-in-button.google,
.login-button.google {
  background-image: url('assets/google-logo.png');
  width: 48px;
  height: 48px;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  margin: 4px 10px 31px;
  background-color: #FFFFFF;
}

.sign-in-button.tiktok,
.login-button.tiktok {
  background-image: url('assets/tiktok-logo.png');
  width: 48px;
  height: 48px;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  margin: 4px 10px 31px;
  background-color: #a7a7a7;
  opacity: 0.8;
}

.sign-in-button.apple,
.login-button.apple {
  background-image: url('assets/apple-logo.png');
  width: 48px;
  height: 48px;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  margin: 4px 10px 31px;
  background-color: #a7a7a7;
  opacity: 0.8;
}

.sign-up-buttons {
  border-bottom: 1px solid;
  margin: 0 12px 20px;
}

.auth-buttons {
  border-bottom: 1px solid;
  margin-bottom: 34px;
  width: 80%;
}

.menu-header-welcome {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  width: 60%;
  border-bottom: 1px solid #D3D3D3;
  padding-bottom: 15px;
}

.header-logged-in {
  display: flex;
  flex-direction: column;
}

.header-logged-out {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.header-logged-out h1 {
  font-family: Nunito;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;  
}

.header-logged-out p {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  text-align: center;
}

.scrubber-section input[type='range'] {
  width: 100%;
  height: 2px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
  -webkit-appearance: none;
}

.scrubber-section input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 3px;
  height: 3px;
  background: #4A4A4A;
  cursor: pointer;
}

.scrubber-section input[type='range']::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}

.scrubber-section-timestamp {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}

.onboarding {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 800;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 999; /* Adjust according to your needs */
  pointer-events: none; /* Prevents clicking on backdrop */
}

.modal {
  position: relative;
  z-index: 1000;
  width: 280px;
  height: 400px;
  box-shadow: 3px 4px 1px #000000;
  border-radius: 5px;
  background: #FFFFFF;
  border: 2px solid #000000;
}

.modal-container {
  border-radius: 5px;
}

.modal-body {
  padding: 12px;
  border-top: 2px solid;
  border-radius: 5px;
  margin-top: -2px;
  font-family: 'Nunito';
}

.modal-body p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}

.onboarding-button {
  background-color: #FFC000;
  color: #000000;
  border: 2px solid #000000;
  width: 120px;
  height: 28px;
  box-shadow: 3px 4px 1px #000000;
  border-radius: 5px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
}

.onboarding-button:active {
  box-shadow: 2px 2px 1px #000000;
}

.modal-header-bg {
  height: 128px;
  background: #84E3FF;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.modal-header-bg.one {
  background-image: url('assets/onboarding-bg-one.png');
  height: 78px;
  width: 254px
}

.modal-header-bg.two {
  background-image: url('assets/onboarding-bg-two.png');
  height: 78px;
  width: 78px;
}

.modal-header-bg.three {
  background-image: url('assets/onboarding-bg-three.png');
  width: 77.11px;
  height: 78px;
}

/* Adding the transition */
.fade-enter {
  opacity: 0;
  transform: scale(.8);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

/* Removing the transition */
.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transform: scale(.8);
  transition: opacity 300ms, transform 300ms;
}

.top-voted-date {
  color: #4A4A4A;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 13px;
  background: #FFFFFF;
  border: 2px solid #000000;
  box-shadow: 3px 4px 1px #000000;
  border-radius: 5px;
}

.top-voted-date .date {
  background: #FFC000;
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 2px 19px;
  color: black;
}

.error-message {
  color: red;
  font-size: 14px;
  display: flex;
  margin: 0 14px;
}

.votes-progress {
  margin-left: 32px;
  display: flex;
}

.playlist-progress {
  margin-bottom: 16px;
}

.playlist-progress .icon {
  background: linear-gradient(135deg, #00B757 0%, #01EE72 53.13%, #00B757 100%);
}

.votes-to-go-live .icon {
  background: linear-gradient(135deg, #FF005D 0%, #FF5E99 52.60%, #FF005D 100%);
}

.playlist-progress .icon ,
.votes-to-go-live .icon {
  display: flex;
  width: 43px;
  align-items: center;
  height: 43px;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid var(--stroke-gray, #D3D3D3);
}

.playlist-progress p,
.votes-to-go-live p {
  margin: 0;
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.playlist-progress span, 
.votes-to-go-live span {
  color: #404040;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.search {
  display: flex;
  justify-content: space-around;
  padding-bottom: 16px;
}

.search button {
  color: #25B1FF !important;
  text-align: right;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  border: none;
  background: none;
}

.search input {
  position: relative;
  width: 100%;
  height: 39px;
  border-radius: 50px;
  border: 1px solid var(--stroke-gray, #D3D3D3);
  background-color: #FFF;
  padding: 0 10px 0 40px;
  background: transparent url("./assets/tabler_search.png") no-repeat left;
  background-position-x: 9px;
}

.search input:focus, .search input:focus-visible {
  border-radius: 50px;
  border: 1px solid #25B1FF;
  background: #FFF;
  outline: none;
  background: transparent url("./assets/tabler_search-active.png") no-repeat left;
  background-position-x: 9px;
}